import * as React from 'react'
import '../../styles/global.css'
import { Link } from 'gatsby'
import {Helmet} from "react-helmet";
import Particles from "react-tsparticles";
import Zoom from 'react-reveal/Fade' // Importing Zoom effect
import Slide from 'react-reveal/Slide';




export default function Branding() {

  const [showModal, setShowModal] = React.useState(true);
  const [showModal2, setShowModal2] = React.useState(false);
  const [showModal3, setShowModal3] = React.useState(false);

  const particlesInit = (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };
    return (
    <div pageTitle="Text Adventure">
      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Architecture and Public Art - USI</title>
        <meta name="description" content="A full list of services we provide."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>

        <link rel="apple-touch-icon" sizes="180x180" href="favicon/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="favicon/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="favicon/favicon-16x16.png"/>
        <link rel="manifest" href="favicon/site.webmanifest"/>
        <meta name="msapplication-TileColor" content="#da532c"/>
        <meta name="theme-color" content="#ffffff"/>
      </Helmet>

      <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        background: {
          color: {
            value: "#192958",
          },
        },
        fpsLimit: 120,
        interactivity: {
          events: {
            onClick: {
              enable: true,
              mode: "push",
            },
            onHover: {
              enable: true,
              mode: "repulse",
            },
            resize: true,
          },
          modes: {
            bubble: {
              distance: 400,
              duration: 20,
              opacity: 0.8,
              size: 40,
            },
            push: {
              quantity: 4,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: "#ffffff",
          },
          links: {
            color: "#ffffff",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            direction: "none",
            enable: true,
            outMode: "bounce",
            random: false,
            speed: 1,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 80,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: "circle",
          },
          size: {
            random: true,
            value: 5,
          },
        },
        detectRetina: true,
      }}
    />

      <div className="py-36">
            {showModal ? (
        <Slide right>
          <div
            className="modal justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none"
          >
            <div className="relative w-screen h-screen mx-auto">
              {/*content*/}
              <div className="modal-branding border-0 relative flex flex-col w-full h-full outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5">
                  <h3 className="text-3xl font-semibold">
                    
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}

                <div className='modal-question grid grid-rows-2 text-center md:w-4/6 mx-auto text-white'>
                  <div className='border-b-2 border-white'>
                    <p className='p-6'>Do you already have a vision and strategy?
                    </p>
                  </div>
                  <div className='grid grid-cols-2'>
                      <button className='hover:bg-gray-900 transition duration-500 ease-in-out transform hover:scale-100 motion-reduce:transform-none p-6 border-r-2 border-white'
                       onClick={() => setShowModal2(true)}>
                        <p className='text-white font-semibold text-4xl'>YES</p>
                      </button>
                      <button className='hover:bg-gray-900 transition duration-500 ease-in-out transform hover:scale-100 motion-reduce:transform-none p-6'>
                        <p className='text-white font-semibold text-4xl'>NO</p>
                      </button>
                  </div>
                </div>
    
                {/*footer*/}
                <div className="flex items-center justify-end p-6">
                  <Link to="../start"
                    className="text-red-500 bg-white font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  >
                    BACK
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </Slide>
      ) : null}
  ); 




  {showModal2 ? (
        <Slide right>
          <div
            className="modal justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none"
          >
            <div className="relative w-screen h-screen mx-auto">
              {/*content*/}
              <div className="modal-branding-2 border-0 relative flex flex-col w-full h-full outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5">
                  <h3 className="text-3xl font-semibold">
                    
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal2(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}

                <div className='modal-question grid grid-rows-2 text-center md:w-4/6 mx-auto text-white'>
                  <div className='border-b-2 border-white'>
                    <p className='p-6'>Do you have a sustainable business model?
                    </p>
                  </div>
                  <div className='grid grid-cols-2'>
                      <button className='hover:bg-gray-900 transition duration-500 ease-in-out transform hover:scale-100 motion-reduce:transform-none p-6 border-r-2 border-white'
                       onClick={() => setShowModal3(true)}>
                        <p className='text-white font-semibold text-4xl'>YES</p>
                      </button>
                      <button className='hover:bg-gray-900 transition duration-500 ease-in-out transform hover:scale-100 motion-reduce:transform-none p-6'>
                        <p className='text-white font-semibold text-4xl'>NO</p>
                      </button>
                  </div>
                </div>
    
                {/*footer*/}
                <div className="flex items-center justify-end p-6">
                <button
                    className="text-red-500 bg-white font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal2(false)}
                  >
                    BACK
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </Slide>
      ) : null}
  ); 



  {showModal3 ? (
        <Slide right>
          <div
            className="modal justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none"
          >
            <div className="relative w-screen h-screen mx-auto">
              {/*content*/}
              <div className="modal-branding-3 border-0 relative flex flex-col w-full h-full outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5">
                  <h3 className="text-3xl font-semibold">
                    
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal3(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}

                <div className='modal-question grid grid-rows-2 text-center md:w-4/6 mx-auto text-white'>
                  <div className='border-b-2 border-white'>
                    <p className='p-6'>Do you know how your brand can be impactful?
                    </p>
                  </div>
                  <div className='grid grid-cols-2'>
                      <button className='hover:bg-gray-900 transition duration-500 ease-in-out transform hover:scale-100 motion-reduce:transform-none p-6 border-r-2 border-white'>
                        <p className='text-white font-semibold text-4xl'>YES</p>
                      </button>
                      <button className='hover:bg-gray-900 transition duration-500 ease-in-out transform hover:scale-100 motion-reduce:transform-none p-6'>
                        <p className='text-white font-semibold text-4xl'>NO</p>
                      </button>
                  </div>
                </div>
    
                {/*footer*/}
                <div className="flex items-center justify-end p-6">
                <button
                    className="text-red-500 bg-white font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal3(false)}
                  >
                    BACK
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </Slide>
      ) : null}
  ); 
      </div>

      </div>
    )
  }
  